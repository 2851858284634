import React, { useCallback, useEffect, useRef } from 'react'
import useReactRouter from 'use-react-router'
import jsYaml from 'js-yaml'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import DropdownField from 'core/components/validatedForm/DropdownField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import ClusterPicklist from 'k8s/components/common/ClusterPicklist'
import NamespacePicklist from 'k8s/components/common/NamespacePicklist'
import moize from 'moize'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Button from 'core/elements/button'
import TextField from 'core/components/validatedForm/TextField'
import CheckboxField from 'core/components/validatedForm/CheckboxField'
import VmTypePicklist from './VmTypePicklist'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import { createVirtualMachine, createVirtualMachineInstance } from '../new-actions'
import { routes } from 'core/utils/routes'
import Progress from 'core/components/progress/Progress'
import { VirtualMachineResourceTypes } from '../model'
import RunStrategyPicklist from './RunStrategyPicklist'
import useUpdateAction from 'core/hooks/useUpdateAction'

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidth: {
    width: '100% !important',
  },
  createVmButton: {
    marginTop: theme.spacing(1),
  },
}))

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const nameValidations = [
  requiredValidator,
  customValidator((name) => {
    return /^[a-z0-9][a-z0-9-.]+[a-z0-9]$/.test(name)
  }, `Name must consist of lower case alphanumeric characters, '-' or '.' and must start and end with an alphanumeric character`),
]

const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body?.apiVersion?.toLowerCase() === 'kubevirt.io/v1'
    } catch (err) {
      return true
    }
  }, 'KubeVirt API version must be "kubevirt.io/v1"'),
  customValidator((yaml) => {
    try {
      const body = moizedYamlLoad(yaml)
      return [
        VirtualMachineResourceTypes.VirtualMachine,
        VirtualMachineResourceTypes.VirtualMachineInstance,
      ].includes(body?.kind)
    } catch (err) {
      return true
    }
  }, 'KubeVirt kind must be "VirtualMachine" or "VirtualMachineInstance"'),
]

const padNumber = (number) => {
  return number.toString().padStart(2, '0')
}

const resetVmNames = (numVms, wizardContext, setWizardContext) => {
  const numVmsToName = numVms - 1
  const newVmNames = [...Array(numVmsToName)].map((_, idx) => {
    const number = padNumber(idx + 1)
    return `${wizardContext.name}-${number}`
  })
  setWizardContext({ vmNames: newVmNames })
}

const setVmNames = (numVms, wizardContext, setWizardContext) => {
  const numVmsToName = numVms - 1
  if (wizardContext.vmNames.length > numVmsToName) {
    setWizardContext({ vmNames: wizardContext.vmNames.slice(0, numVmsToName) })
  } else if (wizardContext.vmNames.length < numVmsToName) {
    const numExtraVms = numVmsToName - wizardContext.vmNames.length
    const startingIndex = wizardContext.vmNames.length + 1
    const newVmNames = [...Array(numExtraVms)].map((_, idx) => {
      const number = padNumber(startingIndex + idx)
      return `${wizardContext.name}_${number}`
    })
    setWizardContext({
      vmNames: [...wizardContext.vmNames, ...newVmNames],
    })
  }
}

const onMultipleVmsChecked = (checked, wizardContext, setWizardContext) => {
  setWizardContext({
    createMultiple: checked,
  })
  if (!checked) {
    return
  }
  // When creating multiple, can only create VM and not VMI
  setWizardContext({
    vmType: 'VirtualMachine',
  })
  resetVmNames(wizardContext.numVms, wizardContext, setWizardContext)
}

const onNumVmsChanged = (value, wizardContext, setWizardContext) => {
  if (value > 10) {
    setWizardContext({ numVms: 10 })
    return setVmNames(10, wizardContext, setWizardContext)
  }
  setWizardContext({ numVms: value })
  setVmNames(value, wizardContext, setWizardContext)
}

// Todo: more specific validations for each field
export const BasicStep = ({ wizardContext, setWizardContext, onNext, initialContext }) => {
  const { history } = useReactRouter()
  const classes = useStyles({})
  const validatorRef = useRef(null)
  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const { update: createVmInstance, updating: creatingVmInstance } = useUpdateAction(
    createVirtualMachineInstance,
  )
  const { update: createVm, updating: creatingVirtualMachine } = useUpdateAction(
    createVirtualMachine,
  )

  const fileInputRef = useRef(null)
  const openFileBrowser = () => {
    fileInputRef.current.click()
  }

  const handleFileUpload = useCallback((e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = function() {
      setWizardContext({ yaml: reader.result })
    }
    reader.onerror = function() {
      console.log('Error reading file')
    }

    reader.readAsText(file)
  }, [])

  // Preserve all the data on this step, but reset all the future steps
  const resetSteps = useCallback(
    (cbWizardContext) => {
      setWizardContext({
        ...initialContext,
        useYaml: cbWizardContext.useYaml,
        name: cbWizardContext.name,
        numVms: cbWizardContext.numVms,
        vmNames: cbWizardContext.vmNames,
        vmType: cbWizardContext.vmType,
        clusterId: cbWizardContext.clusterId,
        namespace: cbWizardContext.namespace,
        yaml: cbWizardContext.yaml,
        runStrategy: cbWizardContext.runStrategy,
      })
    },
    [setWizardContext, initialContext],
  )

  const submitForm = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }
    if (wizardContext.useYaml) {
      const body = moizedYamlLoad(wizardContext.yaml)
      const createMethod = {
        [VirtualMachineResourceTypes.VirtualMachine]: createVm,
        [VirtualMachineResourceTypes.VirtualMachineInstance]: createVmInstance,
      }[body.kind]
      await createMethod({
        yaml: wizardContext.yaml,
        clusterId: wizardContext.clusterId,
        namespace: wizardContext.namespace,
      })
      history.push(routes.kubevirt.overview.path())
    }
    return false
  }, [wizardContext, createVmInstance, createVm, history])

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    return isValid
  }, [wizardContext])

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={setupValidator}
      classes={{ root: classes.validatedFormContainer }}
      fullWidth
      formActions={
        <>
          {wizardContext.useYaml && (
            <Button className={classes.createVmButton} onClick={submitForm}>
              Create VM
            </Button>
          )}
        </>
      }
      elevated={false}
    >
      {wizardContext.useYaml ? (
        <Progress
          overlay
          loading={creatingVmInstance || creatingVirtualMachine}
          renderContentOnMount
        >
          <FormFieldSection
            title="Create VM using YAML"
            link={
              <SimpleLink onClick={() => setWizardContext({ useYaml: false })}>
                Create Using Wizard
              </SimpleLink>
            }
          >
            <DropdownField
              DropdownComponent={ClusterPicklist}
              id="clusterId"
              label="Cluster"
              tooltip="The cluster to deploy this resource on"
              onChange={(value) => setWizardContext({ clusterId: value }, resetSteps)}
              value={wizardContext.clusterId}
              onlyHealthyClusters
              setInitialCluster
              onlyKubevirtClusters
              compact={false}
              showAll={false}
              required
            />
            <DropdownField
              DropdownComponent={NamespacePicklist}
              disabled={!wizardContext.clusterId}
              id="namespace"
              label="Namespace"
              tooltip="The namespace to deploy this resource on"
              clusterId={wizardContext.clusterId}
              onChange={(value) => setWizardContext({ namespace: value }, resetSteps)}
              value={wizardContext.namespace}
              selectFirst={false}
              setInitialNamespace
              compact={false}
              showAll={false}
              required
            />
            <Text variant="body2">
              {'Enter or edit value details below or '}
              <SimpleLink src="" onClick={openFileBrowser}>
                upload a new YAML file.
              </SimpleLink>
              <input
                type="file"
                id="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".yaml"
                onChange={handleFileUpload}
              />
            </Text>
            <CodeMirror
              id="yaml"
              label="YAML Resource"
              validations={codeMirrorValidations}
              onChange={(value) => setWizardContext({ yaml: value })}
              value={wizardContext.yaml}
              options={codeMirrorOptions}
              className={classes.fullWidth}
            />
          </FormFieldSection>
        </Progress>
      ) : (
        <>
          <FormFieldSection
            title="Basic Settings"
            link={
              <SimpleLink onClick={() => setWizardContext({ useYaml: true })}>
                Create Using YAML
              </SimpleLink>
            }
          >
            <TextField
              id="name"
              label="VM Name"
              onChange={(value) => setWizardContext({ name: value })}
              value={wizardContext.name}
              info="Choose a name for your VM"
              validations={nameValidations}
            />
            <CheckboxField
              id="createMultiple"
              value={wizardContext.createMultiple}
              label="Create multiple VMs"
              onChange={(checked) => onMultipleVmsChecked(checked, wizardContext, setWizardContext)}
            />
            {wizardContext.createMultiple && (
              <>
                <TextField
                  id="numVms"
                  label="Number of VMs"
                  onChange={(value) => onNumVmsChanged(value, wizardContext, setWizardContext)}
                  value={wizardContext.numVms}
                  info="Choose the number of VMs to create"
                  type="number"
                  min={2}
                  max={10}
                  required
                />
                {wizardContext.numVms &&
                  [...Array(wizardContext.numVms - 1)].map((_, idx) => (
                    <TextField
                      key={idx}
                      id={idx}
                      label="Name"
                      onChange={(value) => {
                        const vmNames = wizardContext.vmNames
                        vmNames.splice(idx, 1, value)
                        setWizardContext({
                          vmNames,
                        })
                      }}
                      value={wizardContext.vmNames[idx]}
                      info="VM name"
                      validations={nameValidations}
                    />
                  ))}
              </>
            )}
            <DropdownField
              DropdownComponent={ClusterPicklist}
              id="clusterId"
              label="Cluster"
              tooltip="The cluster to deploy this resource on"
              onChange={(value) => setWizardContext({ clusterId: value }, resetSteps)}
              value={wizardContext.clusterId}
              onlyHealthyClusters
              onlyKubevirtClusters
              setInitialCluster
              compact={false}
              showAll={false}
              required
            />
            <DropdownField
              DropdownComponent={NamespacePicklist}
              disabled={!wizardContext.clusterId}
              id="namespace"
              label="Namespace"
              tooltip="The namespace to deploy this resource on"
              clusterId={wizardContext.clusterId}
              onChange={(value) => setWizardContext({ namespace: value }, resetSteps)}
              value={wizardContext.namespace}
              selectFirst={false}
              setInitialNamespace
              compact={false}
              showAll={false}
              required
            />
            <DropdownField
              DropdownComponent={VmTypePicklist}
              id="vmType"
              label="VM Type"
              tooltip="The kubernetes resource type to deploy this VM as"
              onChange={(value) => setWizardContext({ vmType: value })}
              value={wizardContext.vmType as string}
              disabled={wizardContext.createMultiple}
              required
            />
            {wizardContext.vmType === VirtualMachineResourceTypes.VirtualMachine && (
              <DropdownField
                DropdownComponent={RunStrategyPicklist}
                id="runStrategy"
                label="Run Strategy"
                tooltip="The run strategy for this VM"
                onChange={(value) => setWizardContext({ runStrategy: value })}
                value={wizardContext.runStrategy}
                required
              />
            )}
          </FormFieldSection>
        </>
      )}
    </ValidatedForm>
  )
}

export default BasicStep

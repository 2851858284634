import React from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { pick } from 'ramda'
import { routes } from 'core/utils/routes'
import Text from 'core/elements/Text'
import { VirtualMachineResourceTypes, IVirtualMachineDetailsPageTabs } from './model'
import { IVirtualMachineInstanceDetailsPageTabs } from './vmi-model'
import DataKeys from 'k8s/DataKeys'
import { listVirtualMachines, listVirtualMachineInstances } from './new-actions'
import { allVmsSelector } from './selectors'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import InferActionParams from 'core/actions/InferActionParams'
import { GuestOS } from './vmi-model'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { BadgeVariant } from 'core/elements/badge/Badge'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { useAppSelector } from 'app/store'

type ModelDataKey = DataKeys.VirtualMachines | DataKeys.VirtualMachineInstances
type SelectorModel = ArrayElement<ReturnType<typeof allVmsSelector>>
type ActionParams =
  | InferActionParams<typeof listVirtualMachines>
  | InferActionParams<typeof listVirtualMachineInstances>

type Params = ActionParams & {
  clusterId?: string
  namespace?: string
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  namespace: null,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'VirtualMachines',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

// TODO: get all possible status for VM and VMI and split the function
export const getVmStatus = (status) => {
  let variant: BadgeVariant = 'warning'
  // Rest of the statuses should get warning status
  switch (status) {
    case 'Running':
      variant = 'success'
      break
    case 'Terminating':
    case 'CrashLoopBackOff':
    case 'ErrorUnschedulable':
    case 'ErrImagePull':
    case 'ImagePullBackOff':
    case 'ErrorPvcNotFound':
    case 'ErrorDataVolumeNotFound':
    case 'DataVolumeError':
    case 'WaitingForVolumeBinding':
    case 'Stopped':
      variant = 'error'
      break
  }
  return { variant }
}

export const getVmiStatus = (status) => {
  let variant: BadgeVariant = 'warning'
  // Rest of the statuses should get warning status
  switch (status) {
    case 'Running':
      variant = 'success'
      break
    case 'Failed':
      variant = 'error'
      break
  }
  return { variant }
}

const NetworksCell = ({ value = [] }) => {
  return (
    <div>
      {value.map((network) => (
        <Text variant="body2" key={network.networkName}>
          <div>
            <b>Network</b>: {network.networkName}
          </div>
          <div>
            <b>IP Address</b>: {network.ipAddress}
          </div>
          <div>
            <b>MAC Address</b>: {network.mac}
          </div>
        </Text>
      ))}
    </div>
  )
}

const columns: GridViewColumn<SelectorModel>[] = [
  {
    key: 'name',
    label: 'Name',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, vm, vmi }) =>
        vm
          ? routes.virtualMachines.details.path({
              clusterId,
              id: vm?.id,
              tab: IVirtualMachineDetailsPageTabs.Overview,
            })
          : vmi
          ? routes.virtualMachineInstances.details.path({
              clusterId,
              id: vmi?.id,
              tab: IVirtualMachineInstanceDetailsPageTabs.Overview,
            })
          : '',
    }),
  },
  {
    key: 'vm.status.printableStatus',
    label: 'VM Status',
    CellComponent: ({ ...props }) => {
      if (!props.item.vm) {
        return null
      }
      return createGridStatusCell({
        dataFn: getVmStatus,
      })(props)
    },
  },
  {
    key: 'vmi.status.phase',
    label: 'VMI Phase',
    CellComponent: ({ ...props }) => {
      if (!props.item.vmi) {
        return null
      }
      return createGridStatusCell({
        dataFn: getVmiStatus,
      })(props)
    },
  },
  {
    key: 'vmi.networks',
    label: 'Networks',
    CellComponent: NetworksCell,
  },
  {
    key: 'vmi.status.guestOSInfo',
    label: 'Guest OS',
    formatFn: (val: GuestOS) => {
      return val?.prettyName || val?.name
    },
  },
  {
    key: 'clusterName',
    label: 'Cluster',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId }) => routes.cluster.legacy.detail.path({ id: clusterId }),
    }),
  },
  {
    key: 'vmi.status.nodeName',
    label: 'Host',
  },
  { key: 'namespace', label: 'Namespace' },
  {
    key: 'vmi.metadata.labels',
    label: 'Labels',
    disableSorting: true,
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
]

export default function VirtualMachinesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message: vmMessage, loading: loadingVms, reload: reloadVms } = useListAction(
    listVirtualMachines,
    {
      params,
      requiredParams,
    },
  )
  const { message: vmiMessage, loading: loadingVmis, reload: reloadVmis } = useListAction(
    listVirtualMachineInstances,
    {
      params,
      requiredParams,
    },
  )
  const data = useAppSelector(allVmsSelector)

  return (
    <>
      <DocumentMeta title="Virtual Machines" />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.VirtualMachines}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingVms || loadingVmis}
        loadingMessage={vmMessage || vmiMessage}
        onRefresh={() => {
          reloadVms(true, true)
          reloadVmis(true, true)
        }}
        data={data}
        columns={columns}
        addUrl={routes.virtualMachineInstances.add.path({
          createType: VirtualMachineResourceTypes.VirtualMachine,
        })}
        addText="Add Virtual Machine"
        getParamsUpdater={getParamsUpdater}
        multiSelection={false}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

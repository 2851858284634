import { IVirtualMachineInstanceSelector } from './vmi-model'

export enum IVirtualMachineDetailsPageTabs {
  Overview = 'overview',
  Events = 'events',
  Yaml = 'yaml',
}

export enum VirtualMachineCreateTypes {
  AddNew = 'new',
  ImportURL = 'url',
  ImportDisk = 'disk',
  ClonePVC = 'pvc',
}

export enum VirtualMachineResourceTypes {
  VirtualMachine = 'VirtualMachine',
  VirtualMachineInstance = 'VirtualMachineInstance',
}

export enum VMVolumeTypes {
  CloudInitNoCloud = 'cloudInitNoCloud',
  CloudInitConfigDrive = 'cloudInitConfigDrive',
  PersistentVolumeClaim = 'persistentVolumeClaim',
  DataVolume = 'dataVolume',
  Ephemeral = 'ephemeral',
  ContainerDisk = 'containerDisk',
  EmptyDisk = 'emptyDisk',
  HostDisk = 'hostDisk',
  ConfigMap = 'configMap',
  Secret = 'secret',
  ServiceAccount = 'serviceAccount',
}
export const vmVolumeTypes = [
  VMVolumeTypes.CloudInitNoCloud,
  VMVolumeTypes.CloudInitConfigDrive,
  VMVolumeTypes.PersistentVolumeClaim,
  VMVolumeTypes.DataVolume,
  VMVolumeTypes.Ephemeral,
  VMVolumeTypes.ContainerDisk,
  VMVolumeTypes.EmptyDisk,
  VMVolumeTypes.HostDisk,
  VMVolumeTypes.ConfigMap,
  VMVolumeTypes.Secret,
  VMVolumeTypes.ServiceAccount,
]

export interface GetVirtualMachines {
  apiVersion: APIVersion
  items: IVirtualMachine[]
  kind: string
  metadata: GetVirtualMachinesMetadata
}

export enum APIVersion {
  KubevirtIoV1 = 'kubevirt.io/v1',
  KubevirtIoV1Alpha3 = 'kubevirt.io/v1alpha3',
}

export interface IVirtualMachine {
  clusterId: string
  apiVersion: APIVersion
  kind: string
  metadata: ItemMetadata
  spec: ItemSpec
  status: Status
}

export interface ItemMetadata {
  annotations: Record<string, string>
  creationTimestamp: string
  generation: number
  name: string
  namespace: string
  resourceVersion: string
  uid: string
}

export interface ItemSpec {
  dataVolumeTemplates?: DataVolumeTemplate[]
  runStrategy?: string
  template: Template
  running?: boolean
}

export interface DataVolumeTemplate {
  metadata: DataVolumeTemplateMetadata
  spec: DataVolumeTemplateSpec
}

export interface DataVolumeTemplateMetadata {
  creationTimestamp: null
  name: string
}

export interface DataVolumeTemplateSpec {
  pvc: Pvc
  source: Source
}

interface CapacityPvc {
  storage: string
}

export interface StatusPvc {
  phase: string
  accessModes: string[]
  capacity: CapacityPvc
}

export interface Pvc {
  accessModes: string[]
  resources: PvcResources
  volumeName: string
  storageClassName: string
  volumeMode: string
}

interface PvcResources {
  requests: Record<string, string>
}

export interface Source {
  registry?: HTTP
  http?: HTTP
}

export interface HTTP {
  url: string
}

export interface Template {
  metadata: TemplateMetadata
  spec: TemplateSpec
}

export interface TemplateMetadata {
  creationTimestamp: null
  labels: Record<string, string>
}

export interface TemplateSpec {
  domain: Domain
  networks: Network[]
  volumes: Volume[]
}

export interface Domain {
  cpu: CPU
  devices: Devices
  machine: Machine
  resources: DomainResources
}

export interface CPU {
  cores: number
}

export interface Devices {
  disks: DiskElement[]
  interfaces: Interface[]
}

export interface DiskElement {
  disk: DiskDisk
  name: string
}

export interface DiskDisk {
  bus: string
}

export interface Interface {
  bridge?: any
  name: string
  masquerade?: any
}

export interface Machine {
  type: string
}

export interface DomainResources {
  requests: FluffyRequests
}

export interface FluffyRequests {
  memory: string
}

export interface Network {
  name: string
  pod: any
}

export interface Volume {
  dataVolume?: DataVolume
  name: string
  cloudInitNoCloud?: CloudInitNoCloud
}

export interface ContainerDisk {
  image?: string
}

export interface Volume {
  vmType?: VMVolumeTypes
  dataVolume?: DataVolume
  name: string
  cloudInitNoCloud?: CloudInitNoCloud
  containerDisk?: ContainerDisk
}

export interface CloudInitNoCloud {
  userData: string
}

export interface DataVolume {
  name: string
}

export interface Status {
  conditions: Condition[]
  created?: boolean
  printableStatus: string
  ready?: boolean
  volumeSnapshotStatuses: VolumeSnapshotStatus[]
}

export interface Condition {
  lastProbeTime: null | string
  lastTransitionTime: string
  status: string
  type: string
  message?: string
  reason?: string
}

export interface VolumeSnapshotStatus {
  enabled: boolean
  name: string
  reason: string
}

export interface GetVirtualMachinesMetadata {
  continue: string
  resourceVersion: string
}

// details call

export interface GetVirtualMachineDetails {
  apiVersion: string
  kind: string
  metadata: Metadata
  spec: Spec
  status: DetailsStatus
}

interface Metadata {
  annotations: Record<string, string>
  creationTimestamp: string
  finalizers: string[]
  generation: number
  labels: Record<string, string>
  name: string
  namespace: string
  resourceVersion: string
  selfLink: string
  uid: string
}

interface Spec {
  domain: DetailsDomain
  networks: Network[]
  terminationGracePeriodSeconds: number
  volumes: Volume[]
}

interface DetailsDomain {
  cpu: CPU
  devices: Devices
  features: Features
  firmware: Firmware
  machine: Machine
  resources: Resources
}

interface DevicesInterface {
  masquerade?: any
  name: string
  sriov?: any
}

interface Features {
  acpi: ACPI
}

interface ACPI {
  enabled: boolean
}

interface Firmware {
  uuid: string
}

interface Resources {
  requests: Requests
}

interface Requests {
  cpu: string
  memory: string
}

interface DetailsStatus {
  activePods: Record<string, string>
  conditions: Condition[]
  guestOSInfo: any
  interfaces: StatusInterface[]
  migrationMethod: string
  nodeName: string
  phase: string
  qosClass: string
  volumeStatus: VolumeStatus[]
}

interface StatusInterface {
  ipAddress: string
  ipAddresses: string[]
  mac: string
  name: string
}

interface VolumeStatus {
  name: string
  target: string
}

export interface IVirtualMachineSelector extends IVirtualMachine {
  id: string
  name: string
  clusterName: string
  namespace: string
  created: string
  vmi: IVirtualMachineInstanceSelector
  networks: VmNetwork[]
}

export interface VmNetwork {
  networkName: string
}

import React from 'react'
import { VirtualMachineResourceTypes } from '../model'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

const items = [
  { label: 'Virtual Machine Instance', value: VirtualMachineResourceTypes.VirtualMachineInstance },
  { label: 'Virtual Machine', value: VirtualMachineResourceTypes.VirtualMachine },
]

export default function VmTypePicklist(props: PropsWithAsyncDropdown<Props>) {
  return <AsyncDropdown name="vmType" label="VM Type" items={items} {...props} />
}

import React from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import Text from 'core/elements/Text'
import ListTableField from 'core/components/validatedForm/ListTableField'
import renderLabels from 'k8s/components/pods/renderLabels'
import RadioFields from 'core/components/validatedForm/radio-fields'
import { InputAdornment } from '@material-ui/core'
import { listVirtualMachinePresets } from '../new-actions'
import { presetsSelector } from '../selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

const useStyles = makeStyles<Theme>((theme) => ({
  customSpecs: {
    display: 'grid',
    gridAutoFlow: 'column',
    width: 350,
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr',
    marginTop: theme.spacing(1),
  },
  specInput: {
    width: '100% !important',
  },
  parent: {
    position: 'relative',
  },
  unitLabel: {
    position: 'absolute',
    top: 36,
    right: 36,
  },
  sameLine: {
    display: 'flex',
    alignItems: 'center',
  },
  radioLabel: {
    fontSize: 14,
  },
}))

const specifyCustom = [
  {
    key: 'custom',
    value: 'custom',
    label: 'Specify custom values for CPU and Memory',
  },
]

const selectPreset = [
  {
    key: 'preset',
    value: 'preset',
    label: 'Select a Preset',
  },
]

const columns = [
  { id: 'name', label: 'Preset' },
  { id: 'cpu', label: 'VCPUs' },
  { id: 'memory', label: 'RAM' },
  { id: 'labels', label: 'Labels', render: renderLabels('label') },
]

export const PresetsStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})

  const { loading } = useListAction(listVirtualMachinePresets, {
    params: {
      clusterId: wizardContext.clusterId,
      namespace: wizardContext.namespace,
    },
    requiredParams: ['clusterId', 'namespace'],
  })
  const presets = useSelectorWithParams(presetsSelector, {
    clusterId: wizardContext.clusterId,
    namespace: wizardContext.namespace,
  })

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={onNext}
      elevated={false}
    >
      <FormFieldSection title="Presets Selection">
        <Text variant="body2">Select preset options:</Text>
        <div className={classes.sameLine}>
          <RadioFields
            id="presetOption"
            options={specifyCustom}
            value={wizardContext.presetOption}
            onChange={(value) =>
              setWizardContext({
                presetOption: value,
              })
            }
          />
          {wizardContext.presetOption === 'custom' && (
            <div className={classes.customSpecs}>
              <TextField
                id="vcpus"
                label="Cores"
                onChange={(value) => setWizardContext({ vcpus: value })}
                value={wizardContext.vcpus}
                type="number"
                className={classes.specInput}
                min={1}
                required
              />
              <div className={classes.parent}>
                <TextField
                  id="ram"
                  label="Memory"
                  onChange={(value) => setWizardContext({ ram: value })}
                  value={wizardContext.ram}
                  type="number"
                  className={classes.specInput}
                  min={1}
                  required
                />
                <Text variant="body2" className={classes.unitLabel}>
                  GiB
                </Text>
              </div>
            </div>
          )}
        </div>
        <RadioFields
          id="presetOption"
          options={selectPreset}
          value={wizardContext.presetOption}
          onChange={(value) =>
            setWizardContext({
              presetOption: value,
            })
          }
        />
        {wizardContext.presetOption === 'preset' && (
          <ListTableField
            id="selectedPresets"
            data={presets}
            loading={loading}
            columns={columns}
            onChange={(value) => setWizardContext({ selectedPresets: value })}
            value={wizardContext.selectedPresets}
            multiSelection
            required
          />
        )}
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default PresetsStep

import Plugin from 'core/plugins/plugin'
import { AppPlugins } from 'app/constants'
import { kubevirtLogo } from './logo'
import VirtualMachineDetailPage from './components/virtual-machines/details'
import AddVirtualMachinePage from './components/virtual-machines/add/AddVirtualMachineForm'
import EditVirtualMachinePage from './components/virtual-machines/EditVmPage'
import VmOverviewPage from './components/virtual-machines'
import VirtualMachineDetailsPage from './components/virtual-machines/vm-details/VirtualMachineDetailsPage'
import VirtualMachineInstanceDetailsPage from './components/virtual-machines/vmi-details/VirtualMachineInstanceDetailsPage'

const kubevirtPlugin = new Plugin(AppPlugins.KubeVirt, 'KubeVirt', '/ui/kubevirt', kubevirtLogo)
kubevirtPlugin.setOption('singlePane', true)

kubevirtPlugin.registerRoutes([
  {
    name: 'Virtual Machines',
    link: {
      path: '/overview',
      exact: true,
      default: true,
    },
    requiredRoles: 'admin',
    component: VmOverviewPage,
  },
  {
    name: 'Virtual Machine Details',
    link: { path: '/clusters/:clusterId/virtual-machines/:id/:tab?', exact: true },
    component: VirtualMachineDetailsPage,
    requiredRoles: 'admin',
  },
  {
    name: 'Virtual Machine Instance Details',
    link: { path: '/clusters/:clusterId/virtual-machine-instances/:id/:tab?', exact: true },
    component: VirtualMachineInstanceDetailsPage,
    requiredRoles: 'admin',
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/add/new', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/import/url', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/import/disk', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Add Virtual Machine',
    link: { path: '/virtual-machines/clone/pvc', exact: true },
    requiredRoles: 'admin',
    component: AddVirtualMachinePage,
  },
  {
    name: 'Edit Virtual Machine',
    link: { path: '/virtual-machines/clusters/:clusterId/vms/edit/:id', exact: true },
    component: EditVirtualMachinePage,
  },
  {
    name: 'Virtual Machine Details',
    link: { path: '/virtual-machines/:clusterId/:namespace/:name', exact: true },
    requiredRoles: 'admin',
    component: VirtualMachineDetailPage,
  },
])

const navItems = [
  {
    name: 'Overview',
    icon: 'tachometer',
    link: { path: '/overview' },
  },
]

kubevirtPlugin.registerNavItems(navItems)

export default kubevirtPlugin

import { createSelector } from '@reduxjs/toolkit'
import { complement, isNil, pipe, propEq, propSatisfies } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { findClusterName } from 'k8s/util/helpers'
import { filterIf } from 'utils/fp'
import { allKey } from 'app/constants'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { IVirtualMachineInstanceSelector } from './vmi-model'
import { IVirtualMachineSelector } from './model'
import { AppSelector } from 'app/store'

const processVmiNetworks = (vmi) => {
  const networkMap = vmi?.spec?.networks.reduce((accum, current) => {
    if (current.hasOwnProperty('pod')) {
      return {
        ...accum,
        [current.name]: 'Pod Network',
      }
    } else if (current.hasOwnProperty('multus')) {
      return {
        ...accum,
        [current.name]: current?.multus?.networkName,
      }
    }
    return accum
  }, {})
  if (vmi?.status?.interfaces) {
    return vmi?.status?.interfaces?.map((iface) => ({
      networkName: networkMap[iface?.name],
      ipAddress: iface?.ipAddress,
      ipAddresses: iface?.ipAddresses,
      mac: iface?.mac,
    }))
  } else {
    return vmi?.spec?.networks?.map((network) => ({
      networkName: networkMap[network.name],
    }))
  }
}

const processVmNetworks = (vm) => {
  const networks = vm?.spec?.template?.spec?.networks
  // If no networks specified in spec, Pod Network is still present
  return (
    networks?.map((network) => {
      if (network.hasOwnProperty('pod')) {
        return {
          networkName: 'Pod Network',
        }
      } else if (network.hasOwnProperty('multus')) {
        return {
          networkName: network?.multus?.networkName,
        }
      }
      return {
        networkName: 'error',
      }
    }) || [
      {
        networkName: 'Pod Network',
      },
    ]
  )
}

const processVmiCpu = (vmi) => {
  if (vmi?.spec?.domain?.cpu) {
    return vmi.spec.domain.cpu
  }
  const cpu = {} as any
  if (vmi?.spec?.domain?.resources?.requests?.cpu) {
    cpu.requests = vmi.spec.domain.resources.requests.cpu
  }
  if (vmi?.spec?.domain?.resources?.limits?.cpu) {
    cpu.limits = vmi.spec.domain.resources.limits.cpu
  }
  return cpu
}

const processVmCpu = (vm) => {
  if (vm?.spec?.template?.spec?.domain?.cpu) {
    return vm.spec.template.spec.domain.cpu
  }
  const cpu = {} as any
  if (vm?.spec?.template?.spec?.domain?.resources?.requests?.cpu) {
    cpu.requests = vm.spec.template.spec.domain.resources.requests.cpu
  }
  if (vm?.spec?.template?.spec?.domain?.resources?.limits?.cpu) {
    cpu.limits = vm.spec.template.spec.domain.resources.limits.cpu
  }
  return cpu
}

const processVmiMemory = (vmi) => {
  if (vmi?.spec?.domain?.memory) {
    return vmi.spec.domain.memory
  }
  const memory = {} as any
  if (vmi?.spec?.domain?.resources?.requests?.memory) {
    memory.requests = vmi.spec.domain.resources.requests.memory
  }
  if (vmi?.spec?.domain?.resources?.limits?.memory) {
    memory.limits = vmi.spec.domain.resources.limits.memory
  }
  return memory
}

const processVmMemory = (vm) => {
  if (vm?.spec?.template?.spec?.domain?.memory) {
    return vm.spec.template.spec.domain.memory
  }
  const memory = {} as any
  if (vm?.spec?.template?.spec?.domain?.resources?.requests?.memory) {
    memory.requests = vm.spec.template.spec.domain.resources.requests.memory
  }
  if (vm?.spec?.template?.spec?.domain?.resources?.limits?.memory) {
    memory.limits = vm.spec.template.spec.domain.resources.limits.memory
  }
  return memory
}

export const virtualMachineInstancesSelector: AppSelector<IVirtualMachineInstanceSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.VirtualMachineInstances>(
    DataKeys.VirtualMachineInstances,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  (rawVirtualMachineInstances, clusters): IVirtualMachineInstanceSelector[] => {
    return rawVirtualMachineInstances
      .map((vmi) => ({
        ...vmi,
        id: vmi?.metadata?.uid,
        name: vmi?.metadata?.name,
        clusterName: findClusterName(clusters, vmi.clusterId),
        namespace: vmi?.metadata?.namespace,
        created: vmi?.metadata?.creationTimestamp,
        owners: vmi?.metadata?.ownerReferences,
        networks: processVmiNetworks(vmi),
        cpu: processVmiCpu(vmi),
        memory: processVmiMemory(vmi),
        podIds: Object.keys(vmi?.status?.activePods || {}),
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

const filterByVmiType = (vmiType) => (item) => {
  if (vmiType === 'withVm') {
    return !!item.owners
  } else if (vmiType === 'withoutVm') {
    return !item.owners
  }
  return true
}

export const virtualMachinesSelector: AppSelector<IVirtualMachineSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.VirtualMachines>(
    DataKeys.VirtualMachines,
    ['clusterId'],
    ['clusterId', 'namespace'],
  ),
  allClustersSelector,
  virtualMachineInstancesSelector,
  (rawVirtualMachines, clusters, vmis): IVirtualMachineSelector[] => {
    const vmiMap = vmis.reduce((accum, current) => {
      if (current?.owners?.length) {
        for (const owner of current.owners) {
          if (owner.kind === 'VirtualMachine') {
            return {
              ...accum,
              [owner.uid]: current,
            }
          }
        }
      }
      return accum
    }, {})
    return rawVirtualMachines
      .map((virtualMachine) => ({
        ...virtualMachine,
        id: virtualMachine?.metadata?.uid,
        name: virtualMachine?.metadata?.name,
        clusterName: findClusterName(clusters, virtualMachine.clusterId),
        namespace: virtualMachine?.metadata?.namespace,
        created: virtualMachine?.metadata?.creationTimestamp,
        networks: processVmNetworks(virtualMachine),
        vmi: vmiMap[virtualMachine?.metadata?.uid],
        cpu: processVmCpu(virtualMachine),
        memory: processVmMemory(virtualMachine),
      }))
      .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

const combinedFromVms = (vms) =>
  vms.map((vm) => ({
    vm: vm,
    vmi: vm.vmi,
    name: vm?.name,
    namespace: vm?.namespace,
    clusterName: vm?.clusterName,
    clusterId: vm?.clusterId,
    id: vm?.id,
  }))

const combinedFromVmis = (vmis) =>
  vmis.map((vmi) => ({
    vm: undefined,
    vmi: vmi,
    name: vmi?.name,
    namespace: vmi?.namespace,
    clusterName: vmi?.clusterName,
    clusterId: vmi?.clusterId,
    id: vmi?.id,
  }))

export interface CombinedVmsSelector {
  vm: IVirtualMachineSelector
  vmi: IVirtualMachineInstanceSelector
  namespace: string
  clusterName: string
  clusterId: string
  id: string
}

export const allVmsSelector = createSharedSelector(
  virtualMachinesSelector,
  virtualMachineInstancesSelector,
  (vms, vmis): CombinedVmsSelector[] => {
    const vmisFromVmsMap = vms.reduce((accum, current) => {
      if (current?.vmi?.id) {
        return { ...accum, [current.vmi.id]: true }
      }
      return accum
    }, {})
    const vmisWithNoVm = vmis.filter((vmi) => {
      return !vmisFromVmsMap[vmi.id]
    })
    return [...combinedFromVms(vms), ...combinedFromVmis(vmisWithNoVm)]
  },
)

export const presetsSelector = createSharedSelector(
  getDataSelector<DataKeys.VirtualMachinePresets>(DataKeys.VirtualMachinePresets, ['clusterId']),
  allClustersSelector,
  (rawPresets, clusters) => {
    return rawPresets.map((preset) => ({
      ...preset,
      id: preset?.metadata?.uid,
      name: preset?.metadata?.name,
      clusterName: findClusterName(clusters, preset.clusterId),
      namespace: preset?.metadata?.namespace,
      created: preset?.metadata?.creationTimestamp,
      cpu: preset?.spec?.domain?.cpu?.cores,
      memory: preset?.spec?.domain?.resources?.requests?.memory,
      labels: preset?.spec?.selector?.matchLabels,
    }))
  },
)

export const networksSelector = getDataSelector<DataKeys.Networks>(DataKeys.Networks, [
  'clusterId',
  'namespace',
])

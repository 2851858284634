import React from 'react'
import Text from 'core/elements/Text'
import { VmiNetwork } from '../vmi-model'
import { renderLabelsAsBadges } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { IPersistentVolumeClaimsSelector } from 'k8s/components/storage/persistent-volume-claims/model'
import { keys } from 'ramda'
import Tooltip from 'core/elements/tooltip'
import { middleLeft } from 'core/elements/menu/defaults'

export const renderNetwork = (data: VmiNetwork) => (networks, entity) => {
  if (!entity) {
    return ''
  }

  const content = (
    <div>
      <Text variant="caption1">IP Addresses</Text>
      <Text variant="body2">{data?.ipAddresses?.join(', ')}</Text>
      <Text variant="caption1">MAC Address</Text>
      <Text variant="body2">{data?.mac}</Text>
    </div>
  )

  if (entity.kind === 'VirtualMachine') {
    if (!entity.vmi) {
      return 'No information available'
    }
    return content
  } else if (entity.kind === 'VirtualMachineInstance') {
    if (!entity?.status?.interfaces) {
      return 'No information available'
    }
    return content
  }
  return ''
}

export const renderVcpus = (vcpus) => {
  return `${vcpus} vCPUs`
}

const DataVolumeStatusCell = ({ value: status }) => {
  return (
    <>
      <Text variant="body2">{status?.phase}</Text>
      {status?.progress && <Text variant="body2">({status?.progress})</Text>}
    </>
  )
}

const DataVolumeSourceCell = ({ value: source }) => {
  if (!source) {
    return null
  }
  const key = keys(source)?.[0]
  const url = source[key]?.url
  return (
    <>
      <Text variant="body2">{key}</Text>
      {url && (
        <Tooltip message={url} align={middleLeft.align}>
          <Text variant="body2">({url})</Text>
        </Tooltip>
      )}
    </>
  )
}

// Todo: If we make a PVC details page, we can link directly to the PVC rather than
// providing a custom grid on the details page, would be preferable
export const diskColumns = [
  {
    key: 'name',
    label: 'Name',
  },
  { key: 'type', label: 'Type' },
  { key: 'spec.pvc.resources.requests.storage', label: 'Capacity' },
  {
    key: 'spec.pvc.accessModes',
    label: 'Access Modes',
    render: renderLabelsAsBadges({ variant: 'default' }),
  },
  { key: 'status', label: 'Status', CellComponent: DataVolumeStatusCell },
  { key: 'spec.source', label: 'Source', CellComponent: DataVolumeSourceCell },
  { key: 'storageClassName', label: 'Storage Class' },
]

export const getNetworksFields = (entity) => {
  if (!entity) {
    return []
  }
  if (entity.kind === 'VirtualMachine') {
    if (entity.vmi) {
      return entity?.vmi?.networks?.map((network) => {
        return {
          id: 'vmi.networks',
          title: network.networkName,
          render: renderNetwork(network),
        }
      })
    } else if (entity.networks) {
      return entity?.networks?.map((network) => {
        return {
          id: 'networks',
          title: network.networkName,
          render: renderNetwork(network),
        }
      })
    }
  } else if (entity.kind === 'VirtualMachineInstance') {
    return (
      entity?.networks?.map((network) => {
        return {
          id: 'networks',
          title: network.networkName,
          render: renderNetwork(network),
        }
      }) || []
    )
  }
  return []
}

export const getCpuFields = (entity) => {
  if (!entity) {
    return []
  }
  if (entity?.cpu) {
    const cpuKeys = keys(entity.cpu)
    return cpuKeys.map((key) => {
      const keyString = String(key)
      if (['limits', 'requests'].includes(keyString)) {
        return {
          id: `cpu.${keyString}`,
          title: keyString,
          render: renderVcpus,
        }
      }
      return {
        id: `cpu.${keyString}`,
        title: keyString,
      }
    })
  }
  return []
}

export const getMemoryFields = (entity) => {
  if (!entity) {
    return []
  }
  if (entity?.memory) {
    const memoryKeys = keys(entity.memory)
    return memoryKeys.map((key) => {
      const keyString = String(key)
      if (keyString === 'hugepages') {
        return {
          id: 'memory.hugepages.pageSize',
          title: 'Hugepages Size',
        }
      }
      return {
        id: `memory.${keyString}`,
        title: keyString,
      }
    })
  }
  return []
}

const getVolumes = (entity) => {
  return (
    (entity?.kind === 'VirtualMachine'
      ? entity?.spec?.template?.spec?.volumes
      : entity?.spec?.volumes) || []
  )
}

export const getDisks = ({ entity, dataVolumes, pvcs }) => {
  if (!entity) {
    return []
  }
  const volumes = getVolumes(entity)
  return volumes.map((volume) => {
    if (volume?.hasOwnProperty('dataVolume')) {
      const dataVolume = dataVolumes.find((dv) => dv?.name === volume?.dataVolume?.name) || {}
      const pvc =
        pvcs.find((pvc) => pvc?.name === volume?.dataVolume?.name) ||
        ({} as IPersistentVolumeClaimsSelector)
      return {
        name: volume?.dataVolume?.name,
        type: 'dataVolume',
        ...dataVolume,
        storageClassName: pvc?.storageClassName,
      }
    } else if (volume?.hasOwnProperty('cloudInitNoCloud')) {
      return {
        name: volume?.name,
        type: 'cloud-init',
      }
    } else {
      // Todo: Need to see what other disk types are supported
      return {
        name: volume?.name,
        type: 'other',
      }
    }
  })
}

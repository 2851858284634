import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import LabelsAndAnnotationsTabs from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import Progress from 'core/components/progress/Progress'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import Grid from 'core/elements/grid'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { getVmStatus } from '../VmOverviewPage'
import Badge from 'core/elements/badge/Badge'
import { diskColumns, getNetworksFields, getCpuFields, getMemoryFields, getDisks } from './helpers'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
}))

const pvcSelector = makePersistentVolumeClaimSelector()

export const renderStatus = (status) => {
  if (!status) return ''
  const { variant } = getVmStatus(status)
  return <Badge variant={variant} text={status} />
}

const renderRunStrategy = (value, item) =>
  value ? value : `None ${item?.spec?.running ? 'running=true' : 'running=false'}`

const renderVmiReady = (conditions) => {
  const readyCondition = conditions.find((cond) => cond.type === 'Ready')
  return readyCondition?.status
}

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'clusterName', title: 'Cluster', required: true },
  {
    id: 'status.printableStatus',
    title: 'VM Status',
    render: renderStatus,
  },
  {
    id: 'vmi.status.conditions',
    title: 'VMI Ready',
    render: renderVmiReady,
  },
  {
    id: 'spec.runStrategy',
    title: 'Run Strategy',
    render: renderRunStrategy,
  },
  {
    id: 'created',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
]

export default function Overview({ vm, loading }) {
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: vm?.clusterId,
      namespace: vm?.namespace,
    }),
    [vm],
  )

  const { loading: loadingDataVolumes } = useListAction(listDataVolumes, {
    params,
    requiredParams: ['clusterId', 'namespace'],
  })
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, params)

  const { loading: loadingPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(pvcSelector, params)

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, vm)
  }, [vm])

  const networksFields = useMemo(() => getNetworksFields(vm), [vm])

  const networksData = useMemo(() => {
    return getFieldsForCard(networksFields, vm)
  }, [networksFields])

  const cpuFields = useMemo(() => getCpuFields(vm), [vm])

  const cpuData = useMemo(() => {
    return getFieldsForCard(cpuFields, vm)
  }, [cpuFields])

  const memoryFields = useMemo(() => {
    return getMemoryFields(vm)
  }, [vm])

  const memoryData = useMemo(() => {
    return getFieldsForCard(memoryFields, vm)
  }, [memoryFields])

  const disks = useMemo(() => getDisks({ entity: vm, dataVolumes, pvcs }), [vm, dataVolumes, pvcs])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Details"
            footer={
              <LabelsAndAnnotationsTabs
                labels={vm?.vmi?.metadata?.labels}
                annotations={vm?.metadata?.annotations}
              />
            }
          />
          <InfoCard items={networksData} title="Networks" />
        </div>
        <div className={classes.column}>
          <InfoCard items={cpuData} title="CPU" />
          <InfoCard items={memoryData} title="Memory" />
          <Card title="Disks" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={disks}
              columns={diskColumns}
              loading={loadingDataVolumes || loadingPvcs}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

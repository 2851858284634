import React from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { codeMirrorOptions } from 'app/constants'
import { yamlValidator } from 'core/utils/fieldValidators'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidth: {
    width: '100% !important',
  },
}))

const yamlValidations = [yamlValidator]

export const AdvancedConfigurationStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})
  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={onNext}
      elevated={false}
    >
      <FormFieldSection title="Advanced Configuration">
        <Text>Enter or edit value details below.</Text>
        <CodeMirror
          id="advancedYaml"
          label="YAML Resource"
          validations={yamlValidations}
          onChange={(value) => setWizardContext({ advancedYaml: value })}
          value={wizardContext.advancedYaml}
          options={codeMirrorOptions}
          className={classes.fullWidth}
        />
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default AdvancedConfigurationStep

import React, { useCallback, useEffect, useRef } from 'react'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import { codeMirrorOptions } from 'app/constants'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import jsYaml from 'js-yaml'
import SimpleLink from 'core/components/SimpleLink'
import {
  getVolumeJson,
  getVirtualMachineJson,
  getVirtualMachineInstanceJson,
  getNetworkJson,
} from './helpers'
import { yamlValidator } from 'core/utils/fieldValidators'

const useStyles = makeStyles<Theme>((theme) => ({
  fullWidth: {
    width: '100% !important',
  },
}))

const cloudInitValidations = [yamlValidator]

const advancedJsonFunction = {
  VirtualMachine: getVirtualMachineJson,
  VirtualMachineInstance: getVirtualMachineInstanceJson,
}

export const ConfigureStep = ({ wizardContext, setWizardContext, onNext }) => {
  const classes = useStyles({})
  const validatorRef = useRef(null)
  const setupValidator = (validate) => {
    validatorRef.current = { validate }
  }

  const submitStep = useCallback(async () => {
    const isValid = await validatorRef.current.validate()
    if (!isValid) {
      return false
    }

    const { diskSpec, volumeSpec, dataVolumeTemplates } = getVolumeJson({
      storageDisks: wizardContext.storageDisks,
      vmName: wizardContext.name,
      cloudInit: wizardContext.cloudInit,
    })

    const presetLabels = wizardContext?.selectedPresets?.reduce((accum, current) => {
      return {
        ...accum,
        ...current.labels,
      }
    }, {})
    const presetResources =
      wizardContext?.presetOption === 'custom'
        ? {
            cpu: {
              cores: wizardContext?.vcpus,
            },
            resources: {
              requests: {
                memory: `${wizardContext?.ram}G`,
              },
            },
          }
        : {}

    const { interfaceSpec, networkSpec } = getNetworkJson(wizardContext)

    const advancedJson = advancedJsonFunction[wizardContext.vmType]({
      wizardContext,
      diskSpec,
      volumeSpec,
      dataVolumeTemplates,
      presetLabels,
      presetResources,
      interfaceSpec,
      networkSpec,
    })

    const advancedYaml = jsYaml.dump(advancedJson)
    setWizardContext({ advancedYaml })
    return true
  }, [wizardContext])

  useEffect(() => {
    onNext(submitStep)
  }, [submitStep])

  const fileInputRef = useRef(null)
  const openFileBrowser = () => {
    fileInputRef.current.click()
  }

  const handleFileUpload = useCallback((e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = function() {
      setWizardContext({ cloudInit: reader.result })
    }
    reader.onerror = function() {
      console.log('Error reading file')
    }

    reader.readAsText(file)
  }, [])

  return (
    <ValidatedForm
      onSubmit={setWizardContext}
      initialValues={wizardContext}
      triggerSubmit={setupValidator}
      elevated={false}
    >
      <FormFieldSection title="Configure Instance Details">
        <Text variant="body2">
          {'Enter cloud-init script below or '}
          <SimpleLink src="" onClick={openFileBrowser}>
            upload a cloud-init script file
          </SimpleLink>
          <input
            type="file"
            id="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".yaml"
            onChange={handleFileUpload}
          />
        </Text>
        <CodeMirror
          id="cloudInit"
          label="Cloud-Init Script"
          validations={cloudInitValidations}
          onChange={(value) => setWizardContext({ cloudInit: value })}
          value={wizardContext.cloudInit}
          options={codeMirrorOptions}
          className={classes.fullWidth}
        />
      </FormFieldSection>
    </ValidatedForm>
  )
}

export default ConfigureStep

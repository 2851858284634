import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import LabelsAndAnnotationsTabs from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsTabs'
import Progress from 'core/components/progress/Progress'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import Grid from 'core/elements/grid'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { makePersistentVolumeClaimSelector } from 'k8s/components/storage/persistent-volume-claims/selectors'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { dataVolumesSelector } from 'k8s/components/storage/data-volumes/selectors'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { getVmiStatus } from '../VmOverviewPage'
import Badge from 'core/elements/badge/Badge'
import {
  diskColumns,
  getNetworksFields,
  getCpuFields,
  getMemoryFields,
  getDisks,
} from '../vm-details/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  metadataTable: {
    borderSpacing: '20px',
    'last-child': {
      width: '100%',
    },
  },
}))

const pvcSelector = makePersistentVolumeClaimSelector()

export const renderPhase = (status) => {
  if (!status) return ''
  const { variant } = getVmiStatus(status)
  return <Badge variant={variant} text={status} />
}

const renderVmiReady = (conditions) => {
  const readyCondition = conditions.find((cond) => cond.type === 'Ready')
  return readyCondition?.status
}

const metadataFields = [
  { id: 'name', title: 'Name', required: true },
  { id: 'namespace', title: 'Namespace', required: true },
  { id: 'clusterName', title: 'Cluster', required: true },
  {
    id: 'status.conditions',
    title: 'VMI Ready',
    render: renderVmiReady,
  },
  {
    id: 'status.phase',
    title: 'VMI Phase',
    render: renderPhase,
  },
  {
    id: 'created',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
]

export default function Overview({ vmi, loading }) {
  const classes = useStyles()
  const params = useMemo(
    () => ({
      clusterId: vmi?.clusterId,
      namespace: vmi?.namespace,
    }),
    [vmi],
  )

  const { loading: loadingDataVolumes } = useListAction(listDataVolumes, {
    params,
    requiredParams: ['clusterId', 'namespace'],
  })
  const dataVolumes = useSelectorWithParams(dataVolumesSelector, params)

  const { loading: loadingPvcs } = useListAction(listPersistentVolumeClaims, {
    params,
    requiredParams: ['clusterId'],
  })
  const pvcs = useSelectorWithParams(pvcSelector, params)

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, vmi)
  }, [vmi])

  const networksFields = useMemo(() => getNetworksFields(vmi), [vmi])

  const networksData = useMemo(() => {
    return getFieldsForCard(networksFields, vmi)
  }, [networksFields])

  const cpuFields = useMemo(() => getCpuFields(vmi), [vmi])

  const cpuData = useMemo(() => {
    return getFieldsForCard(cpuFields, vmi)
  }, [cpuFields])

  const memoryFields = useMemo(() => getMemoryFields(vmi), [vmi])

  const memoryData = useMemo(() => {
    return getFieldsForCard(memoryFields, vmi)
  }, [memoryFields])

  const disks = useMemo(() => getDisks({ entity: vmi, dataVolumes, pvcs }), [
    vmi,
    dataVolumes,
    pvcs,
  ])

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <InfoCard
            items={metadata}
            title="Metadata"
            footer={
              <LabelsAndAnnotationsTabs
                labels={vmi?.metadata?.labels}
                annotations={vmi?.metadata?.annotations}
              />
            }
          />
          <InfoCard items={networksData} title="Networks" />
        </div>
        <div className={classes.column}>
          <InfoCard items={cpuData} title="CPU" />
          <InfoCard items={memoryData} title="Memory" />
          <Card title="Disks" withCustomBody>
            <Grid
              uniqueIdentifier="name"
              data={disks}
              columns={diskColumns}
              loading={loadingDataVolumes || loadingPvcs}
              compact
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

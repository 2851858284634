import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import FormWrapper from 'core/components/FormWrapper'
import Progress from 'core/components/progress/Progress'
import { routes } from 'core/utils/routes'
import { VirtualMachineResourceTypes } from '../model'
import DocumentMeta from 'core/components/DocumentMeta'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import BasicStep from './BasicStep'
import PresetsStep from './PresetsStep'
import NetworkStep from './NetworkStep'
import StorageStep from './StorageStep'
import ConfigureStep from './ConfigureStep'
import AdvancedConfigurationStep from './AdvancedConfigurationStep'
import ReviewStep from './ReviewStep'
import { createVirtualMachine, createVirtualMachineInstance } from '../new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

const findVMCreateType = (location): VirtualMachineResourceTypes => {
  const createType = new URLSearchParams(location.search).get('createType')
  if (createType) {
    return (createType as VirtualMachineResourceTypes) || VirtualMachineResourceTypes.VirtualMachine
  }
  return VirtualMachineResourceTypes.VirtualMachine
}
const formTitleByType = {
  [VirtualMachineResourceTypes.VirtualMachine]: 'Create a New Virtual Machine',
  [VirtualMachineResourceTypes.VirtualMachineInstance]: 'Create a New VM Instance',
}

const hideWizardButtons = (wizardContext, activeStep) => {
  if (activeStep === 0 && wizardContext.useYaml) {
    return true
  }
  return false
}

export const AddVirtualMachineForm = () => {
  const { history, location } = useReactRouter()
  const vmType = findVMCreateType(location)
  const formattedTitle = formTitleByType[vmType]

  const {
    update: createVmInstance,
    updating: creatingVmInstance,
    error: errorCreatingVmInstance,
  } = useUpdateAction(createVirtualMachineInstance)
  const {
    update: createVm,
    updating: creatingVirtualMachine,
    error: errorCreatingVirtualMachine,
  } = useUpdateAction(createVirtualMachine)

  const initialContext = useMemo(
    () => ({
      name: '',
      clusterId: undefined,
      yaml: undefined,
      advancedYaml: '',
      vmType,
      sourceType: 'url',
      useYaml: false,
      numVms: 3,
      vmNames: [],
      storageMethod: 'create',
      presetOption: 'custom',
      storageDisks: [],
      selectedPresets: [],
      selectedNetworks: [],
      runStrategy: 'Manual',
      podNetworkBindingType: 'masquerade',
    }),
    [vmType],
  )

  const submitForm = useCallback(
    async (wizardContext) => {
      const createMethod = {
        [VirtualMachineResourceTypes.VirtualMachine]: createVm,
        [VirtualMachineResourceTypes.VirtualMachineInstance]: createVmInstance,
      }[wizardContext.vmType]
      const addBody = {
        yaml: wizardContext.advancedYaml,
        clusterId: wizardContext.clusterId,
        namespace: wizardContext.namespace,
      }
      const { success } = await createMethod(addBody)
      if (!success) return
      if (wizardContext.createMultiple) {
        const batchVmsToCreate = wizardContext.vmNames.map((name) => ({
          ...addBody,
          overrideName: name,
        }))
        const result = await Promise.all(batchVmsToCreate.map(createMethod))
        if (!result) return
      }
      history.push(routes.kubevirt.overview.path())
    },
    [history, createVmInstance, createVm],
  )
  const error = errorCreatingVmInstance || errorCreatingVirtualMachine

  return (
    <FormWrapper title={formattedTitle} backUrl={routes.kubevirt.overview.path()}>
      <DocumentMeta title={formattedTitle} bodyClasses={['form-view']} />
      <Progress overlay loading={creatingVmInstance || creatingVirtualMachine} renderContentOnMount>
        <Wizard
          error={error}
          onComplete={submitForm}
          context={initialContext}
          submitLabel="Create"
          hideAllButtons={hideWizardButtons}
        >
          {({ wizardContext, setWizardContext, onNext, handleNext }) => (
            <>
              <WizardStep stepId="step1" label="Basic Settings" keepContentMounted={false}>
                <BasicStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                  initialContext={initialContext}
                />
              </WizardStep>
              <WizardStep stepId="step2" label="Storage" keepContentMounted={false}>
                <StorageStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep stepId="step3" label="Presets" keepContentMounted={false}>
                <PresetsStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep stepId="step4" label="Network" keepContentMounted={false}>
                <NetworkStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep stepId="step5" label="Configure" keepContentMounted={false}>
                <ConfigureStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep stepId="step6" label="Advanced" keepContentMounted={false}>
                <AdvancedConfigurationStep
                  wizardContext={wizardContext}
                  setWizardContext={setWizardContext}
                  onNext={onNext}
                />
              </WizardStep>
              <WizardStep stepId="step7" label="Review" keepContentMounted={false}>
                <ReviewStep wizardContext={wizardContext} />
              </WizardStep>
            </>
          )}
        </Wizard>
      </Progress>
    </FormWrapper>
  )
}

export default AddVirtualMachineForm

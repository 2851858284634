import { noneKey } from 'app/constants'
import { memoize } from 'utils/misc'

const getVolumeSpec = (disk) => {
  const volumeName = `volume-${disk.index + 1}`
  if (['httpUrl', 'registryUrl', 'disk'].includes(disk?.sourceType)) {
    return {
      name: volumeName,
      dataVolume: {
        name: `${disk.vmName}-disk-${disk.index + 1}`,
      },
    }
  } else if (disk?.sourceType === 'clone') {
    return {
      name: volumeName,
      persistentVolumeClaim: {
        claimName: disk.selectedPvcs[0].name,
      },
    }
  } else if (disk?.sourceType === 'volume') {
    return {
      name: volumeName,
      dataVolume: {
        name: disk.selectedDataVolumes[0].name,
      },
    }
  }
  return {}
}

const httpObject = (disk) => ({
  source: {
    http: {
      url: disk.httpUrl,
    },
  },
  pvc: {
    accessModes: [disk.accessMode],
    resources: {
      requests: {
        storage: `${disk.storageSize}Gi`,
      },
    },
    ...(disk.storageClass !== noneKey && { storageClassName: disk.storageClass }),
  },
})

const registryObject = (disk) => ({
  source: {
    registry: {
      url: disk.registryUrl,
    },
  },
  pvc: {
    accessModes: [disk.accessMode],
    resources: {
      requests: {
        storage: `${disk.storageSize}Gi`,
      },
    },
    ...(disk.storageClass !== noneKey && { storageClassName: disk.storageClass }),
  },
})

const diskObject = (disk) => ({
  source: {
    upload: {},
  },
  pvc: {
    accessModes: [disk.accessMode],
    resources: {
      requests: {
        storage: `${disk.storageSize}Gi`,
      },
    },
  },
})

const getDataVolumeObject = (disk) => {
  const objectMethod = {
    httpUrl: httpObject,
    registryUrl: registryObject,
    disk: diskObject,
  }[disk?.sourceType]
  const object = objectMethod ? objectMethod(disk) : null
  return object
}

export const getVolumeJson = memoize(({ storageDisks = [], vmName = '', cloudInit = '' }) => {
  const withIndex = storageDisks.map((disk, idx) => {
    return {
      ...disk,
      index: idx,
      vmName,
    }
  })

  const diskSpec = withIndex.map((disk, idx) => {
    return {
      name: `volume-${disk.index + 1}`,
      disk: {
        bus: 'virtio',
      },
    }
  })

  const fullDiskSpec = [
    ...diskSpec,
    ...(cloudInit
      ? [
          {
            name: 'cloudinit',
            disk: {
              bus: 'virtio',
            },
          },
        ]
      : []),
  ]

  const volumeSpec = withIndex.map((disk) => {
    return getVolumeSpec(disk)
  })

  const dataVolumeTemplates = withIndex
    .filter((disk) => {
      return ['httpUrl', 'registryUrl', 'disk'].includes(disk.sourceType)
    })
    .map((disk) => {
      return {
        metadata: {
          name: `${vmName}-disk-${disk.index + 1}`,
        },
        spec: getDataVolumeObject(disk),
      }
    })

  const fullVolumeSpec = [
    ...volumeSpec,
    ...(cloudInit
      ? [
          {
            name: 'cloudinit',
            cloudInitNoCloud: {
              userDataBase64: window.btoa(unescape(encodeURIComponent(cloudInit))),
            },
          },
        ]
      : []),
  ]

  return {
    diskSpec: fullDiskSpec,
    volumeSpec: fullVolumeSpec,
    dataVolumeTemplates,
  }
})

export const getVirtualMachineJson = ({
  wizardContext,
  diskSpec,
  volumeSpec,
  dataVolumeTemplates,
  presetLabels,
  presetResources,
  interfaceSpec,
  networkSpec,
}) => {
  return {
    apiVersion: 'kubevirt.io/v1',
    kind: 'VirtualMachine',
    metadata: {
      name: wizardContext.name,
    },
    spec: {
      runStrategy: wizardContext.runStrategy,
      template: {
        metadata: {
          labels: wizardContext.presetOption === 'preset' ? presetLabels : {},
        },
        spec: {
          domain: {
            devices: {
              interfaces: interfaceSpec,
              disks: diskSpec,
            },
            ...presetResources,
          },
          networks: networkSpec,
          volumes: volumeSpec,
        },
      },
      dataVolumeTemplates,
    },
  }
}

// dataVolumeTemplates not allowed for VMI
export const getVirtualMachineInstanceJson = ({
  wizardContext,
  diskSpec,
  volumeSpec,
  presetLabels,
  presetResources,
  interfaceSpec,
  networkSpec,
}) => {
  return {
    apiVersion: 'kubevirt.io/v1',
    kind: 'VirtualMachineInstance',
    metadata: {
      name: wizardContext.name,
      labels: wizardContext.presetOption === 'preset' ? presetLabels : {},
    },
    spec: {
      domain: {
        devices: {
          interfaces: interfaceSpec,
          disks: diskSpec,
        },
        ...presetResources,
      },
      networks: networkSpec,
      volumes: volumeSpec,
    },
  }
}

export const getNetworkJson = (wizardContext) => {
  const interfaceSpec = [
    {
      name: 'default',
      [wizardContext.podNetworkBindingType]: {},
    },
    ...wizardContext.selectedNetworks.map((network, index) => {
      return {
        name: `${wizardContext?.name}-interface-${index + 1}`,
        bridge: {},
      }
    }),
  ]

  const networkSpec = [
    {
      name: 'default',
      pod: {},
    },
    ...wizardContext.selectedNetworks.map((network, index) => {
      return {
        name: `${wizardContext?.name}-interface-${index + 1}`,
        multus: {
          networkName: network.name,
        },
      }
    }),
  ]

  return {
    interfaceSpec,
    networkSpec,
  }
}
